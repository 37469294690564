import axios from 'axios';
import consts from '../consts';
import { toastr } from 'react-redux-toastr';
import configLocal from '../configuracao/configLocal';

const BASE_URL = consts.API_URL;

const INITIAL_VALUES = {}

export function getList(id) {    
    const request = axios.get(`${BASE_URL}/grupos/permissoes/${id}`);    
    return {
        type: 'PERMISSAO_FETCHED',
        payload: request
    }
}

export function update(values) {    
    const id = values.id ? values.id : ''

    const request = axios.put(`${BASE_URL}/grupos/permissoes/${id}`, values);
    
    return {
        type: '',
        payload: request
    }

}
