import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import { reset as resetForm, initialize } from 'redux-form'
import consts from '../consts';
import configLocal from '../configuracao/configLocal';


const INITIAL_VALUES = {
    grupoFisicoId : 0,
    contId : 0,
    pf_anel: 1
}

const BASE_URL = consts.API_URL;

export function sendForced(values) {
    
    values.grupoFisicoId = configLocal.getConfig('grupo');
    const request = axios.post(`${BASE_URL}/planoforcado/sendforced`, values);

    return {
        type: '',
        payload: request
    }
}

export function sendCancelForced(values) {
    
    const grupoId = configLocal.getConfig('grupo');    
    const request = axios.delete(`${BASE_URL}/planoforcado/sendforced/${grupoId}/${values.contId}/${values.pf_anel}`);

    return {
        type: '',
        payload: request
    }
}

export function init() {
    return [
        showTabs('tabExec'),
        selectTab('tabExec'),
        initialize('planoForcadoForm', INITIAL_VALUES)
    ]
}

//initialize('planoForm', INITIAL_VALUES)