import axios from 'axios'
import { showTabs, selectTab } from '../common/tab/tabActions'
import consts from '../consts';
import { PAGE_SIZE } from '../common/utils/pageSize';
const BASE_URL = consts.API_URL;

export function getList(page, sorted, filtered) {
    const request = axios.get(`${BASE_URL}/logs/`, { 
        params: {
            page,
            perPage: PAGE_SIZE,
            sort: sorted,
            filter: filtered
        }
    });

    return {
        type: 'USER_LOG_FETCHED',
        payload: request
    }
}

export function getImageById(id) {
    const request = axios.get(`${BASE_URL}/logs/image/${id}`);

    return {
        type: 'USER_LOG_IMAGE_FETCHED',
        payload: request
    }
}

export function init() {
    return [
        showTabs('tabList'),
        selectTab('tabList'),
        getList(0)
    ]
}
