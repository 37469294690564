import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { reset as resetForm, initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';
import consts from '../consts';
const BASE_URL = consts.API_URL;

const INITIAL_VALUES = {controlador_id : configLocal.getConfig('cont')}

export function getList() {

    var contId = configLocal.getConfig('cont');

    const request = axios.get(`${BASE_URL}/adaptativos/controlador/${contId}`)

    return {
        type: 'ADAPTATIVO_FETCHED',
        payload: request
    }
}

export function getListPlano() {// busca os códigos disponiveis para o insert até 32

    var contId = configLocal.getConfig('cont');

    const request = axios.get(`${BASE_URL}/adaptativos/controlador/plano/${contId}`)

    return {
        type: 'ADAPTATIVOPLANO_FETCHED',
        payload: request
    }
}

export function create(values) {
    values.controlador_id = configLocal.getConfig('cont');
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

function submit(values, method) {
    return dispatch => {
        const id = method != 'post' ? values.plano_id : ''
        const contId = values.controlador_id;
        axios[method](`${BASE_URL}/adaptativos/${contId}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function showUpdate(detector) {
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('adaptativoForm', detector)
    ]
}

export function showDelete(detector) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('adaptativoForm', detector)
    ]
}

export function init() {    
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
       getListPlano(),// tem que colocar aqui para atualizar quando salva um novo
        initialize('adaptativoForm', INITIAL_VALUES)
    ]
}
