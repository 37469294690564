import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import consts from '../consts';
const BASE_URL = consts.API_URL;

const INITIAL_VALUES = {}

export function getList() {
    const request = axios.get(`${BASE_URL}/identificadores_telegram`)

    return {
        type: 'IDENTIFICADOR_TELEGRAM_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submitDelete(values);
}

function submit(values, method) {
    const id = method == 'put' ? values.id_chat : ''
    return dispatch => {
        axios[method](`${BASE_URL}/identificadores_telegram/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init());
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

function submitDelete(values) {
    let data = {data: values };

    return dispatch => {
        const id = values.id ? values.id : ''
        axios.delete(`${BASE_URL}/identificadores_telegram/${values.id_chat}`, data)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init());
            })
            .catch(e => {
                let erro = e.response.data.errors;
                erro.forEach(error => toastr.error('Erro', error))
                
            })
    }
}

export function showUpdate(identificadorTelegram) {

    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('identificadorTelegramForm', identificadorTelegram)
    ]
}

export function showDelete(identificadorTelegram) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('identificadorTelegramForm', identificadorTelegram)
    ]
}

export function init() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        initialize('identificadorTelegramForm', INITIAL_VALUES)
    ]
}
