import axios from 'axios'
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';

import consts from '../consts';
import { PAGE_SIZE } from '../common/utils/pageSize';
const BASE_URL = consts.API_URL;

const INITIAL_VALUES = {}

export function getList(page, sorted, filtered) {
    var contId = configLocal.getConfig('cont');
    const request = axios.get(`${BASE_URL}/controladorescontagemvolumetrica`, { 
        params: {
            page,
            perPage: PAGE_SIZE,
            contId,
            sort: sorted,
            filter: filtered
        }
    });

    return {
        type: 'CONTROLADOR_CONTAGEM_VOLUMETRICA_FETCHED',
        payload: request
    }
}

export function getDescriptions() {
    var contId = configLocal.getConfig('cont');
    const request = axios.get(`${BASE_URL}/descricaocontagemvolumetrica/${contId}`);

    return {
        type: 'DESCRICAO_CONTAGEM_VOLUMETRICA_FETCHED',
        payload: request
    }
}

export function init() {
    INITIAL_VALUES.controlador_id = configLocal.getConfig('cont');
    return [
        showTabs('tabList'),
        selectTab('tabList')
    ]
}