import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
// import registerServiceWorker from './registerServiceWorker';

// import requestMiddleware from './requestMiddleware'; 

import promise from 'redux-promise'
import multi from 'redux-multi'
import thunk from 'redux-thunk'

// import Routes from './main/routes'
import AuthOrApp from './main/authOrApp';
import reducers from './main/reducers'
import { register } from './serviceWorker'

// essa linha de comando serve para tornar a aplicação visível para o plugin Redux DevTools do Chrome
const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers, devTools)

/** Intercept any unauthorized request.
* dispatch logout action accordingly **/

ReactDOM.render(
    
    <Provider store={store}>
        <AuthOrApp />
    </Provider>
, document.getElementById('app'))

// registerServiceWorker()
register({onUpdate: () => {
    window.location.reload(true);
}});


