import '../common/template/dependencies'
import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import App from './app'
import Auth from '../auth/auth'
import { validateToken, logout } from '../auth/authActions'

class AuthOrApp extends Component {
    componentWillMount() {
        if (this.props.auth.user) {
            this.props.validateToken(this.props.auth.user.token)
        }
    }

    render() {

        const UNAUTHORIZED = 403;
        axios.interceptors.response.use(
            response => response,
            error => {

                if (error.response) {
                    const { status } = error.response;
                    if (status === UNAUTHORIZED) {
                        this.props.logout();
                    }
                }

                return Promise.reject(error);
            }
        );


        const { user, validToken } = this.props.auth
        if (user && validToken) {
            axios.defaults.headers.common['authorization'] = user.token
            return <App>{this.props.children}</App>
        } else if (!user && !validToken) {
            return <Auth />
        } else {
            return false
        }
    }
}

const mapStateToProps = state => ({ auth: state.auth })
const mapDispatchToProps = dispatch => bindActionCreators({ validateToken, logout }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp)