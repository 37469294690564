import axios from 'axios';
import configLocal from '../configuracao/configLocal';
import moment from 'moment';
import consts from '../consts';
const BASE_URL = consts.API_URL;

export function getSummary() {
    const request = []// axios.get(`${BASE_URL}/billingCycles/summary`)
    return {
        type: 'BILLING_SUMMARY_FETCHED',
        payload: request
    }
}

export function getDadosDashboard() {
    var contId = configLocal.getConfig('cont');
    var data = moment().format("YYYY-MM-DD");   

    const request = axios.get(`${BASE_URL}/dashboard/dados/${contId}/${data}`);    

    return {
        type: '',
        payload: request
    }  
}
