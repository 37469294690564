import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import consts from '../consts';
import { getList } from './usuarioActions';
const BASE_URL = consts.API_URL;

const INITIAL_VALUES = {}

export function getNotificacoesUsuario(id) {
    const request = axios.get(`${BASE_URL}/notificacaoesusuario/${id}`)
    
    return {
        type: 'NOTIFICACAO_USUARIO_FETCHED',
        payload: request
    }
}

export function changeNotificacao(values) {
    return dispatch => {
        axios.post(`${BASE_URL}/notificacaoesusuario`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function showNotificaoes(usuario) {
    return [
        showTabs('tabNotificacao'),
        selectTab('tabNotificacao'),
        getNotificacoesUsuario(usuario.id),
        initialize('usuarioNotificacoesForm', {
            id: usuario.id,
            nome: usuario.nome,
            filtrar_notificacao: usuario.filtrar_notificacao
        })
    ]
}

export function init() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        { type: 'NOTIFICACAO_USUARIO_CLEAR' },
        initialize('usuarioNotificacoesForm', INITIAL_VALUES)
    ]
}