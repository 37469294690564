import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { reset as resetForm, initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import consts from '../consts';
const BASE_URL = consts.API_URL;

const INITIAL_VALUES = {}

export function getList() {
    const request = axios.get(`${BASE_URL}/tipoalarme`)

    return {
        type: 'TIPO_ALARME_FETCHED',
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

function submit(values, method) {

    return dispatch => {
        const id = values.id ? values.id : ''
        axios[method](`${BASE_URL}/tipoalarme/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function showUpdate(tipoalarme) {
    
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate'),
        initialize('tipoAlarmeForm', tipoalarme)
    ]
}

export function showDelete(tipoalarme) {
    return [
        showTabs('tabDelete'),
        selectTab('tabDelete'),
        initialize('tipoAlarmeForm', tipoalarme)
    ]
}

export function init() {
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getList(),
        initialize('tipoAlarmeForm', INITIAL_VALUES)
    ]
}
