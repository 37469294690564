import axios from 'axios';
import configLocal from '../configuracao/configLocal';
import moment from 'moment';

import consts from '../consts';
const BASE_URL = consts.API_URL;

export function getContagemGraficoList(filtro) {
    var contId = configLocal.getConfig('cont');
    const dataIni = filtro.dataInicial;
    const dataFim = filtro.dataFinal;

    const request = axios.get(`${BASE_URL}/dashboard/contagem/${contId}/${dataIni}/${dataFim}`);
    
    return {
        type: 'GRAFICO_CONTAGEM_FETCHED',
        payload: request
    }
}
