import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset as initialize } from 'redux-form';
import { showTabs, selectTab } from '../common/tab/tabActions';
import configLocal from '../configuracao/configLocal';

import consts from '../consts';
const BASE_URL = consts.API_URL;
// const BASE_URL = 'http://localhost:3003/api';

// const INITIAL_VALUES = {};
// é utilizado para poder recarregar os valores padrões do cadastro de plano
const INITIAL_VALUES = () => ({
    controlador_id: 0,
    status: 1,
    numero_intervalo: 4,
    modo_operacao_id: 0,
    imageLog: ''
})

export function getList(contid, planoid) {

    const request = axios.get(`${BASE_URL}/intervalos/${contid}/${planoid}`)

    return {
        type: 'INTERVALO_FETCHED',
        payload: request
    }
}

export function getListInterval(contid, planoid) {

    const numCont = configLocal.getConfig('numCont');

    const com = 38;
    const request = axios.get(`${BASE_URL}/intervalos/remote/${com}/${contid}/${planoid}/${numCont}`);

    return {
        type: 'INTERVALO_FETCHED',
        payload: request
    }
}

// export function getListSerialTempoReal(contid) {

//     const com = 36;
//     const request = axios.get(`${BASE_URL}/intervalos/remote/${com}/${contid}/0`);

//     return {
//         type: 'INTERVALO_FETCHED',
//         payload: request
//     }
// }

// export function create(values) {
//     return submit(values, 'post');
// }

export function update(values) {
    return submit(values, 'put')
}

function submit(values, method) {

    return async dispatch => {

        // const controlador_id = values.controlador_id;
        // const plano_id = values.plano_id ? values.plano_id : ''
        // const id = values.id ? values.id : ''

        await axios[method](`${BASE_URL}/intervalos`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                //dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function updatePlano(values) {

    // let contId = configLocal.getConfig('cont');
    let contId = values.controlador_id;

    const request = axios.put(`${BASE_URL}/intervalosPlano/${contId}`, values);

    return {
        type: '',
        payload: request
    }

}

export function updateSerial(values) {

    const contId = configLocal.getConfig('cont');
    const numCont = configLocal.getConfig('numCont');

    const request = axios.put(`${BASE_URL}/intervalos/remote/${contId}/${numCont}`, values);

    return {
        type: '',
        payload: request
    }

}

export function updatePlanoIntermitente(ativar) {
    let contId = configLocal.getConfig('cont');
    // let grupoId = configLocal.getConfig('grupo');

    const request = axios.put(`${BASE_URL}/intervalos/planointermitente/${contId}/${ativar}`);

    return {
        type: '',
        payload: request
    }
}

export function init() {
    return [
        getList()
    ]
}

export function getListPlano() {

    var numCont = configLocal.getConfig('numCont');
    var grupo = configLocal.getConfig('grupo');
    const request = axios.get(`${BASE_URL}/planos/controlador/numero/${numCont}/${grupo}`)

    return {
        type: 'PLANOCONTROLADOR_FETCHED',
        payload: request
    }
}

export function getControllerPhase() {

    let contId = configLocal.getConfig('cont');
    // let numCont = configLocal.getConfig('numCont');

    const request = axios.get(`${BASE_URL}/fasesgeral/mapa/${contId}`);

    return {
        type: ``,
        payload: request
    }
}

export function initPlano() {

    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        getListPlano(),
        initialize('planoForm', INITIAL_VALUES())
    ]
}
