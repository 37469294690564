import React, { Component } from 'react';
import Grid from '../common/layout/grid';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Button from '../common/form/button';
import { testarConexao, enviarRelogioControlador } from './controladorActions';
import { toastr } from 'react-redux-toastr';
import ReactLoading from 'react-loading';
import { Modal } from 'react-bootstrap';
import PermissaoUsuario from '../auth/permissao';
import { Field } from 'redux-form';
import labelAndInput from '../common/form/labelAndInput';

// import { StatusCon } from './status';

class ControladorConexao extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    getConexao(enderecoUDP) {

        window.$('#dataHoraControlador').val('Conectando...');

        this.props.testarConexao(enderecoUDP, this.props.numCont)
            .then((result) => {

                window.$('#dataHoraControlador').val(result.payload.data.data + '  ' + result.payload.data.hora);

                window.$('#btnTestarConexao').removeClass('btn-default');
                window.$('#btnTestarConexao').removeClass('btn-warning');
                window.$('#btnTestarConexao').addClass('btn-success');
                window.$('#statusConexaoCont').html('<b>Status:</b> Online');

                // dispatch([
                //     { type: 'CONEXAO_CONTROLE', dataHora: resp.data, conectado: true }
                // ])
            })
            .catch((e) => {
                window.$('#dataHoraControlador').val('Sem conexão');
                window.$('#statusConexaoCont').html('<b>Status:</b> Offline');

                window.$('#btnTestarConexao').removeClass('btn-default');
                window.$('#btnTestarConexao').removeClass('btn-success');
                window.$('#btnTestarConexao').addClass('btn-warning');
                toastr.error('Erro', 'Sem conexão. O número ou endereço do controlador podem estar errados.');
                // e.response.data.errors.forEach(
                //     error => toastr.error('Erro', error))
            })

    }

    enviarRelogio(enderecoUDP) {
        this.setState({ show: true });

        this.props.enviarRelogioControlador(enderecoUDP, this.props.numCont)
            .then((result) => {
                this.setState({ show: false });

                if (result.error) {
                    toastr.error('Erro', result.payload.response.data.errors[0]);
                } else {
                    toastr.success('Sucesso', 'Operação Realizada com sucesso.');
                }
            })
            .catch(e => {
                this.setState({ show: false });
                toastr.error('Erro', e);
            });
    }

    render() {
        const enderecoUDP = this.props.udp || [];

        return (
            <div>
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    dialogClassName='modal-load'
                    backdrop={false} >
                    <Modal.Body className='modal-body modal-load-body'>
                        <div >
                            <div >
                                <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                            </div>
                            <div >
                                <h4><b>Aguarde, processando...</b></h4>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Grid cols={this.props.cols}>
                    
                    <Field
                        name='dataHoraControlador'
                        id='dataHoraControlador'
                        component={labelAndInput}
                        label='Data/Hora'
                        cols='12 3'
                        readOnly={true}
                    /> 
                    <div class=" col-xs-12 col-sm-3 ">
                        <div id='statusConexaoCont'><b>Status:</b></div>
                    
                        <PermissaoUsuario tela="CadControlador" campo="testar_conexao" condicao={true}>
                            <Button type='button' id='btnTestarConexao' className='primary' icon='plug' label='Testar Conexão' onClick={() => this.getConexao(enderecoUDP)} />
                        </PermissaoUsuario>

                        <PermissaoUsuario tela="CadControlador" campo="testar_conexao" condicao={false}>
                            <Button type='button' id='btnTestarConexao' className='primary' icon='plug' label='Testar Conexão' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                
                    <div class=" col-xs-12 col-sm-6">
                        <div><b>&nbsp;</b></div>
                        <PermissaoUsuario tela="CadControlador" campo="enviar_relogio" condicao={true}>
                            <Button type='button' id='btnEnviarRelogioControlador' className='primary' icon='arrow-up' label='Enviar Data/Hora' onClick={() => this.enviarRelogio(enderecoUDP)} />
                        </PermissaoUsuario>
                        <PermissaoUsuario tela="CadControlador" campo="enviar_relogio" condicao={false}>
                            <Button type='button' id='btnEnviarRelogioControlador' className='primary' icon='arrow-up' label='Enviar Data/Hora' disabled="disabled" />
                        </PermissaoUsuario>
                    </div>
                        
                </Grid>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ testarConexao, enviarRelogioControlador }, dispatch)
export default connect(null, mapDispatchToProps)(ControladorConexao)

//export default ControladorConexao;