/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import html2canvas from 'html2canvas';
// import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import { exportComponentAsJPEG, exportComponentAsPNG } from 'react-component-export-image';

import {
    init
    , getList
    , getFaseList
    , getFaseListNovo
    , create
    , enviarTemposControlador
    , lerQuantidadeAneis
    , lerTemposControlador
    , lerDadosFasesControlador
    , enviarHorarioVeraoControlador
    , enviaDadosFasesControlador
    , getLocalCEP
    , getImageCroquiById
} from './controladorActions';

import LabelAndInput from '../common/form/labelAndInput';
// import LabelAndSelectForm from '../common/form/labelAndSelectForm';
// import checkboxFase from '../common/form/checkboxFase';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import Button from '../common/form/button';
import Checkbox from '../common/form/checkbox';
// import checkboxFase from '../common/form/checkboxFase';
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { toastr } from 'react-redux-toastr';

// import SelectControlador from './selectControlador';
// import SelectSequenciaControlador from './selectSequenciaControlador';
import SelectFaseControlador from './selectFaseControlador';

import swal from 'sweetalert2';
import If from '../common/operator/if';
import ConexaoUDPTeste from './controladorConexao';

import { Marker } from "react-google-maps";
import MapaControlador from './mapaControlador';
import SelectNumeroControlador from '../common/selectdb/selectNumeroControlador';// foi desativado pq agora pode repetir o número do controlador
import ListFase from '../fase/faseList';
import PermissaoUsuario from '../auth/permissao';
import FaseConflitoControlador from './controladorFaseConflito';
import { validaPermissaoDisabled, validaPermissaoReadOnly } from '../auth/permissaoActions';

const InputValidacao = require('../common/form/inputValidacao.js');
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

class ControladorForm extends Component {

    constructor(props) {
        super(props);

        this.componentRef = React.createRef();

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.clickOk = this.clickOk.bind(this);
        this.renderMarker = this.renderMarker.bind(this);
        // this.submitForm = this.submitForm.bind(this);

        this.enviarFaseTempoControlador = this.enviarFaseTempoControlador.bind(this);
        this.lerFaseTempoControlador = this.lerFaseTempoControlador.bind(this);
        this.enviarHorarioVeraoControlador = this.enviarHorarioVeraoControlador.bind(this);
        this.onBlurVerdeMinimo = this.onBlurVerdeMinimo.bind(this);
        this.geraGridFase = this.geraGridFase.bind(this);
        this.salvarControlador = this.salvarControlador.bind(this);
        this.cepOnBlur = this.cepOnBlur.bind(this);
        this.verificaAlteracao = this.verificaAlteracao.bind(this);
        this.anelChange = this.anelChange.bind(this);

        this.croquiHandleFileChange = this.croquiHandleFileChange.bind(this)
        this.deleteCroquiFile = this.deleteCroquiFile.bind(this)
        this.renderFileInput = this.renderFileInput.bind(this)

        this.state = {
            show: false,
            showModal: false,
            lat: 0,
            lng: 0,
            cepLat: props.latitude || 0,
            cepLng: props.longitude || 0,
            html: '',
            htmlMapaCont: '',
            fases: [],
            cep: props.cep || 0,
            OldValues: this.props.valores,
            OldFases: [],
            messageModal: '',
            carregouFase: false,
            showTab: 'tabAnel',
            // Croqui File
            croquiFileSelected: null,
            croquiFileErrorMessage: '',
            showCroquiDeleteWarning: false,
            // anelSelect: this.props.valores.anel
        };
    }

    
    enviarHorarioVeraoControlador() {
        swal({
            title: 'Enviar as configurações de Horário de Verão para a "Central"?',
            html: '<h5>Essa ação vai substituir as configurações existentes na central.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.setState({ showModal: true }); // da show no modal de aguarde

                const data = {
                    ip: this.props.enderecoUDP.ip,
                    porta: this.props.enderecoUDP.porta,
                    data_inicio_verao: window.$('#data_inicio_verao').val(),
                    data_fim_verao: window.$('#data_fim_verao').val(),
                    numCont: this.props.valores.numero_controlador
                };

                this.props.enviarHorarioVeraoControlador(data)
                    .then((result) => {
                        this.setState({ showModal: false });

                        if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                            toastr.error('Erro', result.payload.response.data.errors[0]);
                        } else {
                            toastr.success('Sucesso', 'Horário de Verão enviado com sucesso.');
                        }

                    })
                    .catch(e => {
                        this.setState({ showModal: false });
                        toastr.error('Erro', e);
                    });
            }
        });
    }

    enviarFaseTempoControlador() {
        swal({
            title: 'Enviar as configurações de Tempos e Fases para a Central?',
            html: '<h5>Essa ação vai substituir as configurações existentes na central.</h5>',
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: 'primary',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: 'false'
        }).then((result) => {
            if (result.value) {
                this.setState({ showModal: true }); // da show no modal de aguarde

                const dataTempo = {
                    ip: this.props.enderecoUDP.ip,
                    porta: this.props.enderecoUDP.porta,
                    tempo_vermelho_min: window.$('#tempo_vermelho_min').val(),
                    tempo_amarelo_min: window.$('#tempo_amarelo_min').val(),
                    tempo_verde_min: window.$('#tempo_verde_min').val(),
                    numCont: this.props.valores.numero_controlador,
                    anel: this.props.valores.anel
                };

                const dataFases = {
                    ip: this.props.enderecoUDP.ip,
                    porta: this.props.enderecoUDP.porta,
                    fases: this.state.fases,
                    numCont: this.props.valores.numero_controlador,
                    anel: this.props.valores.anel,
                    conflitos: [{
                        numCont: this.props.valores.numero_controlador,
                        numero_fases: this.props.valores.numero_fases,

                        fase1: [
                            this.props.valores.fase1_fase2 == true ? 1 : 0,
                            this.props.valores.fase1_fase3 == true ? 1 : 0,
                            this.props.valores.fase1_fase4 == true ? 1 : 0,
                            this.props.valores.fase1_fase5 == true ? 1 : 0,
                            this.props.valores.fase1_fase6 == true ? 1 : 0,
                            this.props.valores.fase1_fase7 == true ? 1 : 0,
                            this.props.valores.fase1_fase8 == true ? 1 : 0,
                            this.props.valores.fase1_fase9 == true ? 1 : 0,
                            this.props.valores.fase1_fase10 == true ? 1 : 0,
                            this.props.valores.fase1_fase11 == true ? 1 : 0,
                            this.props.valores.fase1_fase12 == true ? 1 : 0,
                            this.props.valores.fase1_fase13 == true ? 1 : 0,
                            this.props.valores.fase1_fase14 == true ? 1 : 0,
                            this.props.valores.fase1_fase15 == true ? 1 : 0,
                            this.props.valores.fase1_fase16 == true ? 1 : 0
                        ],

                        fase2: [
                            this.props.valores.fase2_fase3 == true ? 1 : 0,
                            this.props.valores.fase2_fase4 == true ? 1 : 0,
                            this.props.valores.fase2_fase5 == true ? 1 : 0,
                            this.props.valores.fase2_fase6 == true ? 1 : 0,
                            this.props.valores.fase2_fase7 == true ? 1 : 0,
                            this.props.valores.fase2_fase8 == true ? 1 : 0,
                            this.props.valores.fase2_fase9 == true ? 1 : 0,
                            this.props.valores.fase2_fase10 == true ? 1 : 0,
                            this.props.valores.fase2_fase11 == true ? 1 : 0,
                            this.props.valores.fase2_fase12 == true ? 1 : 0,
                            this.props.valores.fase2_fase13 == true ? 1 : 0,
                            this.props.valores.fase2_fase14 == true ? 1 : 0,
                            this.props.valores.fase2_fase15 == true ? 1 : 0,
                            this.props.valores.fase2_fase16 == true ? 1 : 0
                        ],

                        fase3: [
                            this.props.valores.fase3_fase4 == true ? 1 : 0,
                            this.props.valores.fase3_fase5 == true ? 1 : 0,
                            this.props.valores.fase3_fase6 == true ? 1 : 0,
                            this.props.valores.fase3_fase7 == true ? 1 : 0,
                            this.props.valores.fase3_fase8 == true ? 1 : 0,
                            this.props.valores.fase3_fase9 == true ? 1 : 0,
                            this.props.valores.fase3_fase10 == true ? 1 : 0,
                            this.props.valores.fase3_fase11 == true ? 1 : 0,
                            this.props.valores.fase3_fase12 == true ? 1 : 0,
                            this.props.valores.fase3_fase13 == true ? 1 : 0,
                            this.props.valores.fase3_fase14 == true ? 1 : 0,
                            this.props.valores.fase3_fase15 == true ? 1 : 0,
                            this.props.valores.fase3_fase16 == true ? 1 : 0
                        ],

                        fase4: [
                            this.props.valores.fase4_fase5 == true ? 1 : 0,
                            this.props.valores.fase4_fase6 == true ? 1 : 0,
                            this.props.valores.fase4_fase7 == true ? 1 : 0,
                            this.props.valores.fase4_fase8 == true ? 1 : 0,
                            this.props.valores.fase4_fase9 == true ? 1 : 0,
                            this.props.valores.fase4_fase10 == true ? 1 : 0,
                            this.props.valores.fase4_fase11 == true ? 1 : 0,
                            this.props.valores.fase4_fase12 == true ? 1 : 0,
                            this.props.valores.fase4_fase13 == true ? 1 : 0,
                            this.props.valores.fase4_fase14 == true ? 1 : 0,
                            this.props.valores.fase4_fase15 == true ? 1 : 0,
                            this.props.valores.fase4_fase16 == true ? 1 : 0
                        ],

                        fase5: [
                            this.props.valores.fase5_fase6 == true ? 1 : 0,
                            this.props.valores.fase5_fase7 == true ? 1 : 0,
                            this.props.valores.fase5_fase8 == true ? 1 : 0,
                            this.props.valores.fase5_fase9 == true ? 1 : 0,
                            this.props.valores.fase5_fase10 == true ? 1 : 0,
                            this.props.valores.fase5_fase11 == true ? 1 : 0,
                            this.props.valores.fase5_fase12 == true ? 1 : 0,
                            this.props.valores.fase5_fase13 == true ? 1 : 0,
                            this.props.valores.fase5_fase14 == true ? 1 : 0,
                            this.props.valores.fase5_fase15 == true ? 1 : 0,
                            this.props.valores.fase5_fase16 == true ? 1 : 0
                        ],

                        fase6: [
                            this.props.valores.fase6_fase7 == true ? 1 : 0,
                            this.props.valores.fase6_fase8 == true ? 1 : 0,
                            this.props.valores.fase6_fase9 == true ? 1 : 0,
                            this.props.valores.fase6_fase10 == true ? 1 : 0,
                            this.props.valores.fase6_fase11 == true ? 1 : 0,
                            this.props.valores.fase6_fase12 == true ? 1 : 0,
                            this.props.valores.fase6_fase13 == true ? 1 : 0,
                            this.props.valores.fase6_fase14 == true ? 1 : 0,
                            this.props.valores.fase6_fase15 == true ? 1 : 0,
                            this.props.valores.fase6_fase16 == true ? 1 : 0
                        ],

                        fase7: [
                            this.props.valores.fase7_fase8 == true ? 1 : 0,
                            this.props.valores.fase7_fase9 == true ? 1 : 0,
                            this.props.valores.fase7_fase10 == true ? 1 : 0,
                            this.props.valores.fase7_fase11 == true ? 1 : 0,
                            this.props.valores.fase7_fase12 == true ? 1 : 0,
                            this.props.valores.fase7_fase13 == true ? 1 : 0,
                            this.props.valores.fase7_fase14 == true ? 1 : 0,
                            this.props.valores.fase7_fase15 == true ? 1 : 0,
                            this.props.valores.fase7_fase16 == true ? 1 : 0
                        ],

                        fase8: [
                            this.props.valores.fase8_fase9 == true ? 1 : 0,
                            this.props.valores.fase8_fase10 == true ? 1 : 0,
                            this.props.valores.fase8_fase11 == true ? 1 : 0,
                            this.props.valores.fase8_fase12 == true ? 1 : 0,
                            this.props.valores.fase8_fase13 == true ? 1 : 0,
                            this.props.valores.fase8_fase14 == true ? 1 : 0,
                            this.props.valores.fase8_fase15 == true ? 1 : 0,
                            this.props.valores.fase8_fase16 == true ? 1 : 0
                        ],

                        fase9: [
                            this.props.valores.fase9_fase10 == true ? 1 : 0,
                            this.props.valores.fase9_fase11 == true ? 1 : 0,
                            this.props.valores.fase9_fase12 == true ? 1 : 0,
                            this.props.valores.fase9_fase13 == true ? 1 : 0,
                            this.props.valores.fase9_fase14 == true ? 1 : 0,
                            this.props.valores.fase9_fase15 == true ? 1 : 0,
                            this.props.valores.fase9_fase16 == true ? 1 : 0
                        ],

                        fase10: [
                            this.props.valores.fase10_fase11 == true ? 1 : 0,
                            this.props.valores.fase10_fase12 == true ? 1 : 0,
                            this.props.valores.fase10_fase13 == true ? 1 : 0,
                            this.props.valores.fase10_fase14 == true ? 1 : 0,
                            this.props.valores.fase10_fase15 == true ? 1 : 0,
                            this.props.valores.fase10_fase16 == true ? 1 : 0
                        ],

                        fase11: [
                            this.props.valores.fase11_fase12 == true ? 1 : 0,
                            this.props.valores.fase11_fase13 == true ? 1 : 0,
                            this.props.valores.fase11_fase14 == true ? 1 : 0,
                            this.props.valores.fase11_fase15 == true ? 1 : 0,
                            this.props.valores.fase11_fase16 == true ? 1 : 0
                        ],

                        fase12: [
                            this.props.valores.fase12_fase13 == true ? 1 : 0,
                            this.props.valores.fase12_fase14 == true ? 1 : 0,
                            this.props.valores.fase12_fase15 == true ? 1 : 0,
                            this.props.valores.fase12_fase16 == true ? 1 : 0
                        ],

                        fase13: [
                            this.props.valores.fase13_fase14 == true ? 1 : 0,
                            this.props.valores.fase13_fase15 == true ? 1 : 0,
                            this.props.valores.fase13_fase16 == true ? 1 : 0
                        ],

                        fase14: [
                            this.props.valores.fase14_fase15 == true ? 1 : 0,
                            this.props.valores.fase14_fase16 == true ? 1 : 0
                        ],

                        fase15: [
                            this.props.valores.fase15_fase16 == true ? 1 : 0
                        ],

                        // fase1: [
                        //     this.props.valores.fase1_fase2 == true ? 1 : 0,
                        //     this.props.valores.fase1_fase3 == true ? 1 : 0,
                        //     this.props.valores.fase1_fase4 == true ? 1 : 0,
                        //     this.props.valores.fase1_fase5 == true ? 1 : 0,
                        //     this.props.valores.fase1_fase6 == true ? 1 : 0,
                        //     this.props.valores.fase1_fase7 == true ? 1 : 0,
                        //     this.props.valores.fase1_fase8 == true ? 1 : 0
                        // ],
                        // fase2: [
                        //     this.props.valores.fase2_fase3 == true ? 1 : 0,
                        //     this.props.valores.fase2_fase4 == true ? 1 : 0,
                        //     this.props.valores.fase2_fase5 == true ? 1 : 0,
                        //     this.props.valores.fase2_fase6 == true ? 1 : 0,
                        //     this.props.valores.fase2_fase7 == true ? 1 : 0,
                        //     this.props.valores.fase2_fase8 == true ? 1 : 0
                        // ],
                        // fase3: [
                        //     this.props.valores.fase3_fase4 == true ? 1 : 0,
                        //     this.props.valores.fase3_fase5 == true ? 1 : 0,
                        //     this.props.valores.fase3_fase6 == true ? 1 : 0,
                        //     this.props.valores.fase3_fase7 == true ? 1 : 0,
                        //     this.props.valores.fase3_fase8 == true ? 1 : 0
                        // ],
                        // fase4: [
                        //     this.props.valores.fase4_fase5 == true ? 1 : 0,
                        //     this.props.valores.fase4_fase6 == true ? 1 : 0,
                        //     this.props.valores.fase4_fase7 == true ? 1 : 0,
                        //     this.props.valores.fase4_fase8 == true ? 1 : 0
                        // ],
                        // fase5: [
                        //     this.props.valores.fase5_fase6 == true ? 1 : 0,
                        //     this.props.valores.fase5_fase7 == true ? 1 : 0,
                        //     this.props.valores.fase5_fase8 == true ? 1 : 0
                        // ],
                        // fase6: [
                        //     this.props.valores.fase6_fase7 == true ? 1 : 0,
                        //     this.props.valores.fase6_fase8 == true ? 1 : 0
                        // ],
                        // fase7: [
                        //     this.props.valores.fase7_fase8 == true ? 1 : 0
                        // ]
                    }]
                }

                this.props.enviarTemposControlador(dataTempo)
                    .then((result) => {

                        if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                            toastr.error('Erro', result.payload.response.data.errors[0]);
                            this.setState({ showModal: false });
                        } else {
                            this.props.enviaDadosFasesControlador(dataFases)
                                .then((resultFase) => {
                                    this.setState({ showModal: false });

                                    if (resultFase.error) {
                                        toastr.error('Erro', resultFase.payload.response.data.errors[0]);
                                    } else {
                                        this.props.handleSubmit();
                                        // toastr.success('Sucesso', 'Tempos/Fases enviados com sucesso.');
                                    }
                                })
                                .catch((Error) => {
                                    this.setState({ showModal: false });
                                    toastr.error('Erro', Error);
                                });
                        }
                    })
                    .catch(e => {
                        this.setState({ showModal: false });
                        e.response.data.errors.forEach(error => toastr.error('Erro', error));
                    });
            }
        });
    }

    async lerFaseTempoControlador() {
        const endereco = this.props.enderecoUDP;

        this.setState({ showModal: true });

        this.setState({
            html: <div>Carregando...</div>
        });

        await sleep(100);

        this.props.lerQuantidadeAneis(endereco, this.props.valores.numero_controlador, this.props.valores.anel)
            .catch(e => {
                this.setState({ showModal: false });
                toastr.error('Erro', 'Não foi possivel identificar a quantidade de aneis');
            })
            .then(async (resultAnel) => {

                if (resultAnel) {
                    let erro = false;
                    this.props.change('endereco', '');// zera o endereço quando lê
                    this.props.change('cruzamento', '');// zera o cruzamento quando lê
                    for (let idx = 0; idx < resultAnel.payload.data.quantidade && !erro; idx++) {
                        let anelId = idx + 1;
                        this.setState({ messageModal: `Buscando dados "Anel ${anelId}/${resultAnel.payload.data.quantidade}"` });
                        this.props.valores.aneis[idx].ativo = 1;

                        // this.props.lerTemposControlador(endereco, this.props.valores.numero_controlador, this.props.valores.anel)
                        await this.props.lerTemposControlador(endereco, this.props.valores.numero_controlador, anelId)
                            .then(async (result) => {
                                if (result.error) {//se retornar true no erro, chama o metodo que pega do banco
                                    toastr.error('Erro', result.payload.response.data.errors[0]);
                                    this.setState({ showModal: false, messageModal: '' });
                                } else {
                                    await this.props.lerDadosFasesControlador(endereco, this.props.valores.numero_controlador, anelId)
                                        // this.props.lerDadosFasesControlador(endereco, this.props.valores.numero_controlador, this.props.valores.anel)
                                        .then((resultFase) => {
                                            // this.setState({ showModal: false, messageModal:'' });
                                            let bSucesso = false;

                                            if (resultFase.error) {
                                                toastr.error('Erro', resultFase.payload.response.data.errors[0]);
                                                this.setState({ showModal: false, messageModal: '' });
                                            } else {
                                                let dataFase = [];
                                                let faseInicial = parseInt(resultFase.payload.data[0].fases_vermelho.inicio_fase);
                                                let faseFinal = parseInt(resultFase.payload.data[0].fases_vermelho.total_fases);
                                                const idxAnel = anelId - 1;
                                                // dataFase = this.state.fases;

                                                // Percorre a lista "fases_vermelho" porque tem o mesmo número de posições
                                                // da lista "fases_piscantes", então conseguimos acessar as propriedades de ambas as listas
                                                for (let index = faseInicial; index <= faseFinal; index++) {

                                                    // if (!faseJaExiste) {
                                                    if (index <= dataFase.length) {
                                                        dataFase[index - 1].ausencia_vermelho = resultFase.payload.data[0].fases_vermelho[index];
                                                        dataFase[index - 1].cor_piscante = resultFase.payload.data[0].fases_piscantes[index];
                                                    } else {
                                                        dataFase.push({
                                                            controlador_id: this.props.controladorId || 0,
                                                            id: index,
                                                            ausencia_vermelho: resultFase.payload.data[0].fases_vermelho[index],
                                                            cor_piscante: resultFase.payload.data[0].fases_piscantes[index],
                                                            cor_piscante_pedestre: 4,
                                                            ativo: 0,
                                                            latitude: 0,
                                                            longitude: 0,
                                                            tipo: resultFase.payload.data[0].fases_piscantes[index] === 4 ? 2 : 1,
                                                            tempo_vermelho_min: resultFase.payload.data[0].fases_tempoMinimo[index].vrm,
                                                            tempo_amarelo_min: resultFase.payload.data[0].fases_tempoMinimo[index].amr,
                                                            tempo_verde_min: resultFase.payload.data[0].fases_tempoMinimo[index].vrd,
                                                        });
                                                    }
                                                    // } else {
                                                    //     dataFase[index - 1].ausencia_vermelho = resultFase.payload.data[0].fases_vermelho[index];
                                                    //     dataFase[index - 1].cor_piscante = resultFase.payload.data[0].fases_piscantes[index];
                                                    // }
                                                }

                                                // this.props.valores.fases = dataFase;
                                                this.props.valores.aneis[idxAnel].endereco = '';
                                                this.props.valores.aneis[idxAnel].cruzamento = '';
                                                this.props.valores.aneis[idxAnel].fases = dataFase;//o array de anel inicia de 0
                                                this.props.valores.aneis[idxAnel].numero_fases = faseInicial;//o array de anel inicia de 0
                                                this.props.valores.aneis[idxAnel].numero_fases_fim = faseFinal;//o array de anel inicia de 0
                                                this.props.change('numero_fases', faseInicial);
                                                this.props.change('numero_fases_fim', faseFinal);

                                                // let totalFase = parseInt(resultFase.payload.data[0].fases_vermelho.total_fases);

                                                // marca os conflitos das fases
                                                for (let f = faseInicial + 1; f <= faseFinal; f++) {
                                                    for (let i = faseInicial; i < f; i++) {
                                                        this.props.valores.aneis[idxAnel][`fase${i}_fase${f}`] = resultFase.payload.data[0].fases_conflitantes[`fase${i}_fase${f}`];
                                                        this.props.change(`fase${i}_fase${f}`, resultFase.payload.data[0].fases_conflitantes[`fase${i}_fase${f}`] == 1 ? true : false);
                                                    }
                                                }

                                                this.setState({ fases: dataFase });
                                                this.carregaLatLngFase();
                                                this.setState({
                                                    html: <ListFase
                                                        data-id={this.props.controladorId}
                                                        data-ini-fase={faseInicial}
                                                        data-total-fase={dataFase.length}
                                                        data-fases={this.state.fases}
                                                    />
                                                });

                                                if ((result.payload.data[0].tempo_vermelho_min != null) &&
                                                    (result.payload.data[0].tempo_amarelo_min != null) &&
                                                    (result.payload.data[0].tempo_verde_min != null)) {

                                                    bSucesso = true;

                                                    this.props.valores.aneis[idxAnel].tempo_vermelho_min = result.payload.data[0].tempo_vermelho_min;
                                                    this.props.valores.aneis[idxAnel].tempo_amarelo_min = result.payload.data[0].tempo_amarelo_min;
                                                    this.props.valores.aneis[idxAnel].tempo_verde_min = result.payload.data[0].tempo_verde_min;

                                                    this.props.change('tempo_vermelho_min', result.payload.data[0].tempo_vermelho_min);
                                                    this.props.change('tempo_amarelo_min', result.payload.data[0].tempo_amarelo_min);
                                                    this.props.change('tempo_verde_min', result.payload.data[0].tempo_verde_min);
                                                } else {
                                                    toastr.error('Erro', 'Não foi possível realizar a leitura, tente novamente.');
                                                }

                                                if (bSucesso) {
                                                    toastr.success('Sucesso', 'Fases e Tempos lidos com sucesso.');
                                                }
                                            }
                                        })
                                        .catch((err) => {
                                            erro = true;
                                            toastr.error('Erro', 'Erro ao ler fazes do controlador');
                                            this.setState({ showModal: false, messageModal: '' });
                                        });
                                }
                            })
                            .catch(e => {
                                erro = true;
                                this.setState({ showModal: false, messageModal: '' });
                                toastr.error('Erro', 'Erro ao ler tempos do controlador');
                            });
                    }

                    // se a quantidade for menor que 4 tem que desativar as outras
                    for (let idx = resultAnel.payload.data.quantidade; idx < 4; idx++) {
                        if (this.props.valores.aneis[idx]) {
                            this.props.valores.aneis[idx].ativo = 0;
                        }
                    }

                    // se tudo der certo termina o modal de processando
                    this.setState({ showModal: false, messageModal: '' });
                    // tem que receber o valor do anel selecionado.
                    this.setState({ fases: this.props.valores.aneis[this.props.valores.anel - 1].fases });
                } else {
                    toastr.error('Erro', 'Perda de conexão. Não foi possível realizar a leitura, tente novamente.');
                    this.setState({ showModal: false, messageModal: '' });
                }
            });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    async handleShow() {

        this.setState({
            htmlMapaCont: <div>Carregando...</div>
        });

        await sleep(100);

        this.setState({
            htmlMapaCont:
                <MapaControlador
                    data-contId={this.props.controladorId}
                    data-latitude={this.state.cepLat}
                    data-longitude={this.state.cepLng}
                    latitude={this.state.cepLat}
                    longitude={this.state.cepLng}
                />
        });

        if (this.props.latitude == null) {
            if (this.props.cep) {
                this.setState({ show: true });
                // this.props.getLocalCEP(this.props.cep)
                //     .then((result) => {
                //         this.setState({ cepLat: result.payload.data.location.lat, cepLng: result.payload.data.location.lng });
                //         this.setState({ show: true });
                //     })
                //     .catch((erro) => {
                //         console.log(erro);
                //     });
            } else {
                toastr.warning('Alerta', 'Informe o campo CEP');
            }
        } else {
            this.setState({ show: true });
        }

    }

    handleShowModal() {
        this.setState({ showModal: true });
    }

    handleShowTab(name) {
        this.setState({ showTab: name});
    }

    async clickOk() {
        this.props.change('latitude', parseFloat(window.$('#controador_latitude').val()));
        this.props.change('longitude', parseFloat(window.$('#controador_longitude').val()));

        this.setState({
            cepLat: parseFloat(window.$('#controador_latitude').val()),
            cepLng: parseFloat(window.$('#controador_longitude').val())
        });

        if (!this.props.controladorId) {
            this.setState({
                html: <div>Carregando...</div>
            });

            await sleep(100);

            this.carregaLatLngFaseInsert();
            this.setState({
                html: <ListFase
                    data-id={this.props.controladorId}
                    data-total-fase={this.props.numeroFases}
                    data-fases={this.state.fases}
                />
            });
        }

        this.setState({ show: false });
    }

    renderMarker(lat, lng) {
        const list = this.props.getList() || [];

        return list.map(contro => (

            <Marker
                position={{ lat: contro.latitude, lng: contro.longitude }} //{ lat: -27.217136, lng: -49.644149 }
                draggable={true}
                icon={'http://maps.google.com/mapfiles/ms/icons/green-dot.png'}

                onMouseUp={this.handleMarkerMouseUp}
            />

        ));
    }

    onBlurVerdeMinimo(event) {
        let tempo_verde_arredondado = window.$('#tempo_verde_min').val();
        tempo_verde_arredondado = Math.round(tempo_verde_arredondado);

        window.$('#tempo_verde_min').val(parseInt(tempo_verde_arredondado));
    }

    // submitForm() {
    //     this.props.create();
    // }

    async geraGridFase(anelSelect) {
        this.setState({
            html: <div>Carregando...</div>
        });

        // const anelSelect = this.props.valores.anel - 1;

        await sleep(100);

        let idCont = (this.props.valores.aneis[anelSelect].id) ? this.props.valores.aneis[anelSelect].id : this.props.controladorId;

        // if (this.props.controladorId != null) {

        if ((this.props.valores.aneis[anelSelect].fases) && (this.props.valores.aneis[anelSelect].fases.length)) {
            this.setState({ fases: this.props.valores.aneis[anelSelect].fases });
            // se só fazer o OldFases receber o resultFase.payload.data, o setState atualiza ele
            let oldFasesData = [];
            for (const key in this.props.valores.aneis[anelSelect].fases) {
                const element = this.props.valores.aneis[anelSelect].fases[key];
                oldFasesData.push(
                    {
                        ausencia_vermelho: element.ausencia_vermelho,
                        cor_piscante: element.cor_piscante
                    }
                )
            }

            this.setState({ OldFases: oldFasesData });
            this.carregaLatLngFase();
            this.setState({
                html: <ListFase
                    data-id={idCont}
                    data-total-fase={this.props.numeroFases}
                    data-fases={this.state.fases}
                />
            });

            this.setState({ carregouFase: true });// usado para tirar a foto para o log, tem que esperar carregar para depois tirar a foto

        } else {

            if (idCont != null) {

                this.props.getFaseList(idCont, this.props.valores.aneis[anelSelect].numero_fases, this.props.valores.aneis[anelSelect].numero_fases_fim)
                    .then((resultFase) => {
                        this.setState({ fases: resultFase.payload.data });
                        // se só fazer o OldFases receber o resultFase.payload.data, o setState atualiza ele
                        let oldFasesData = [];
                        for (const key in resultFase.payload.data) {
                            const element = resultFase.payload.data[key];
                            oldFasesData.push(
                                {
                                    ausencia_vermelho: element.ausencia_vermelho,
                                    cor_piscante: element.cor_piscante
                                }
                            )
                        }

                        this.setState({ OldFases: oldFasesData });
                        this.carregaLatLngFase();
                        this.setState({
                            html: <ListFase
                                data-id={idCont}
                                data-total-fase={this.props.numeroFases}
                                data-fases={this.state.fases}
                            />
                        });

                        this.setState({ carregouFase: true });// usado para tirar a foto para o log, tem que esperar carregar para depois tirar a foto

                    })
            } else {
                this.props.getFaseListNovo(this.props.valores.aneis[anelSelect].numero_fases, this.props.valores.aneis[anelSelect].numero_fases_fim)
                    .then((resultFase) => {
                        this.props.valores.aneis[anelSelect].fases = resultFase.payload.data;
                        this.setState({ fases: resultFase.payload.data });
                        this.setState({ OldFases: [{ id: 2, gt: 'teste 2' }] });
                        this.carregaLatLngFase();
                        this.setState({
                            html: <ListFase
                                data-id={idCont}
                                data-total-fase={this.state.fases.length}
                                data-fases={this.state.fases}
                            />
                        });

                        this.setState({ carregouFase: true });// usado para tirar a foto para o log, tem que esperar carregar para depois tirar a foto

                    })
            }
        }
    }

    componentWillMount() {
        this.geraGridFase(0);// posição zero anel padrão 1
    }

    verificaAlteracao() {

        for (const key in this.state.OldValues) {
            if (key.indexOf('_fase') >= 0) {
                if (this.state.OldValues[key] != this.props.valores[key]) {
                    return true;
                }
            }
        }

        if ((this.state.OldValues.tempo_amarelo_min != this.props.valores.tempo_amarelo_min) ||
            (this.state.OldValues.tempo_verde_min != this.props.valores.tempo_verde_min) ||
            (this.state.OldValues.tempo_vermelho_min != this.props.valores.tempo_vermelho_min) ||
            (this.state.OldValues.numero_fases != this.props.valores.numero_fases)) {
            return true;
        }

        for (const key in this.state.OldFases) {
            const element = this.state.OldFases[key];
            let item = this.props.valores.fases[key];

            if (item && (element.ausencia_vermelho != item.ausencia_vermelho || element.cor_piscante != item.cor_piscante)) {
                return true;
            }
        }

        return false;
    }

    anelChange(anel) {
        // let anel = event.target.value - 1;

        this.props.change('endereco', this.props.valores.aneis[anel].endereco);
        this.props.change('cruzamento', this.props.valores.aneis[anel].cruzamento);
        this.props.change('numero_fases', this.props.valores.aneis[anel].numero_fases);
        this.props.change('numero_fases_fim', this.props.valores.aneis[anel].numero_fases_fim);

        this.props.change('tempo_vermelho_min', this.props.valores.aneis[anel].tempo_vermelho_min);
        this.props.change('tempo_amarelo_min', this.props.valores.aneis[anel].tempo_amarelo_min);
        this.props.change('tempo_verde_min', this.props.valores.aneis[anel].tempo_verde_min);

        this.geraGridFase(anel);

    }

    async salvarControlador() {

        const anelList = this.props.valores.aneis;
        let anelValid = true;

        // o anel atual recebe a fase do state para gravar corretamento a longitudo e latitude
        this.props.valores.aneis[this.props.valores.anel - 1].fases = this.state.fases;

        for (const idx in anelList) {
            if (anelList[idx].ativo === 1) {
                if (anelList[idx].numero_fases === anelList[idx].numero_fases_fim) {
                    this.props.change('anel', anelList[idx].anel);
                    this.anelChange(anelList[idx].anel - 1)
                    anelValid = false;
                    swal({
                        type: 'warning',
                        title: 'Fases inicial e final não podem ser iguais'
                    });
                    break;
                }

                if ((anelList[idx].endereco.trim() === '') || (anelList[idx].cruzamento.trim() === '')) {
                    this.props.change('anel', anelList[idx].anel);
                    this.anelChange(anelList[idx].anel - 1)
                    anelValid = false;

                    swal({
                        type: 'warning',
                        title: 'Informe os campos obrigatórios "Endereço" e "Cruzamento"'
                    });
                    break;
                }
            }
        }

        if (anelValid) {
            if (!this.props.valid) {
                swal({
                    type: 'warning',
                    title: 'Informe os campos obrigatórios'
                });
                this.props.handleSubmit();// fica aqui para pintar os campo em vermelho
            } else {

                this.props.valores.fases = this.state.fases;
                // this.props.valores.aneis[this.props.valores.anel - 1].endereco = this.props.valores.endereco;
                // this.props.valores.aneis[this.props.valores.anel - 1].cruzamento = this.props.valores.cruzamento;
                this.setState({ showModal: true }); // da show no modal de aguarde

                // carrega a foto de todos os aneis
                for (const key in anelList) {
                    if (parseInt(anelList[key].ativo) === 1) {
                        this.setState({ carregouFase: false });
                        await sleep(200);
                        this.props.change('anel', anelList[key].anel);
                        this.anelChange(anelList[key].anel - 1);
                        // await sleep(400);
                        while (!this.state.carregouFase) {
                            // console.log('this.state.carregouFase',this.state.carregouFase);
                            await sleep(400);
                            // fica preso aqui até carregar a fase e setar a variavel carregouFase como true
                        }

                        await html2canvas(this.componentRef.current)
                            .then((canvas) => {
                                canvas.style.display = 'none';
                                let image = canvas.toDataURL("png");
                                // console.log('this.props.valores.imageLog ANTES>>> ',this.props.valores.imageLog);
                                this.props.valores.aneis[key].imageLog = image;
                                anelList[key].imageLog = image;
                            });
                    }
                }

                if (!this.verificaAlteracao()) {
                    this.props.handleSubmit();
                    // this.setState({ showModal: false, messageModal: '' });
                } else {
                    this.props.handleSubmit();// o cliente não queria fazer configuração base pela central. Então só lê e salva no banco
                    this.setState({
                        fases: [] ,
                        html: <div></div>,
                        showModal: false,
                        messageModal: ''
                    });
                }
            }
        }

    }

    carregaLatLngFase() {
        let dataFases = this.state.fases;

        for (const key in dataFases) {
            if (parseInt(dataFases[key].latitude) === 0) {
                dataFases[key].latitude = this.state.cepLat;
            }

            if (parseInt(dataFases[key].longitude) === 0) {
                dataFases[key].longitude = this.state.cepLng;
            }
        }
        this.setState({ fases: dataFases });
    }

    carregaLatLngFaseInsert() {
        let dataFases = this.state.fases;

        for (const key in dataFases) {
            dataFases[key].latitude = this.state.cepLat;

            dataFases[key].longitude = this.state.cepLng;

        }
        this.setState({ fases: dataFases });
    }

    cepOnBlur(event) {

        if ((this.props.latitude == null) || (event.target.value != this.state.cep)) {
            if ((event.target.value)) {
                if (event.target.value) {
                    this.setState({ cep: event.target.value });
                }
                this.props.getLocalCEP(event.target.value)
                    .then((result) => {

                        this.setState({ cepLat: result.payload.data.location.lat, cepLng: result.payload.data.location.lng });
                        this.props.change('latitude', this.state.cepLat);
                        this.props.change('longitude', this.state.cepLng);
                        this.carregaLatLngFase();

                        this.setState({
                            html: <ListFase
                                data-id={this.props.controladorId}
                                data-total-fase={5}
                                data-change={true}
                                data-fases={this.state.fases}
                            />
                        });

                    })
                    .catch((erro) => {
                        console.log(erro);
                    });
            }
        }
    }

    renderFieldLaco() {
        let lacos = [];

        for (let index = 1; index <= 16; index++){
            let name = 'laco_' + index,
                label = 'Laço ' + index;

            lacos.push(
                <Field name={name} id={name} component={LabelAndInput}
                    label={label} cols='12 6' placeholder='Informe a descrição do laço' />
            );
        }
        return lacos;
    }

    croquiHandleFileChange (event) {
        const croquiFileSelected = event.target.files[0];

        if (croquiFileSelected) {
            
            // Verificar se o arquivo é um PDF
            if (croquiFileSelected.type !== 'application/pdf') {
                this.setState({ 
                    croquiFileSelected: null,
                    croquiFileErrorMessage: 'Por favor, selecione um arquivo PDF.'
                });
                return;
            }

            // Verificar o tamanho do arquivo (1 MB = 1048576 bytes)
            if (croquiFileSelected.size > 1048576) {
                this.setState({ 
                    croquiFileSelected: null,
                    croquiFileErrorMessage: 'O arquivo selecionado excede o tamanho máximo permitido (1 MB).'
                });
                return;
            }

            this.setState({
                croquiFileSelected,
                croquiFileErrorMessage: ''
            });

            const reader = new FileReader();
            reader.readAsDataURL(croquiFileSelected);
            reader.onload = () => {
                const croquiFileBase64 = reader.result;
                this.props.change('croqui_file_data', croquiFileBase64);
            };
            reader.onerror = () => {
              console.error('Erro ao ler o arquivo.');
            };
        }
    }
    deleteCroquiFile() {
        this.props.change('croqui_file_id', null);
        this.setState({ showCroquiDeleteWarning: true });
    }

    async getPdf(croqui_file_id) {
        try {
            const response = await this.props.getImageCroquiById(croqui_file_id);
    
            if (response) {
                // Cria um URL temporário para o blob retornado pela API
                const url = window.URL.createObjectURL(new Blob([response.payload.data]));
        
                // Cria um link temporário e o clica para iniciar o download
                const link = document.createElement('a');
                link.href = url;
            
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('Erro ao baixar o arquivo');
            }
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    }

    renderFileInput ({ input, meta }) {
        return (
            <div>
            {this.props.croqui_file_id !== null && (
                <div>
                    <label>Croqui processado:</label>
                    <input name="croqui_file_id" class="form-control" readOnly={true} value={this.props.croqui_file_id + '.pdf'} />
                    <br></br>
                    <Button type='button' className='success btn' icon='search' label='Visualizar croqui' onClick={async () => this.getPdf(this.props.croqui_file_id)} />
                    <Button type='button' className='danger' icon='trash-alt' label='Deletar croqui' onClick={this.deleteCroquiFile} />
                </div>
            )}
            {this.props.croqui_file_id === null && (
                <input
                name="croqui_file"
                id="croqui_file"
                type="file"
                onChange={this.croquiHandleFileChange}
                />
            )}
            </div>
        )
    }

    render() {

        // const required = value => (value || typeof value === 'number' ? undefined : 'Required');
        const required = value => value ? undefined : 'Obrigatório';

        const requiredSelect = value => (((value === 'selecione') || (value == undefined)) ? 'Obrigatório' : undefined);
        // const requiredSelect1 = value => (value == '' ? 'Required' : undefined);

        const minLength = min => value => value && value.length < min ? `Deve ter ${min} caracteres ou mais` : undefined;
        const minLengthCEPDesc = min => value => value && value.length < min ? `Deve ter ${min} caracteres` : undefined;
        const minLength2 = minLength(2);
        const minLengthCEP = minLengthCEPDesc(8);

        const { handleSubmit, readOnly, enderecoUDP, numeroFases } = this.props;

        let aneisAtivos = ['', 'hidden', 'hidden', 'hidden'],
            aneis = this.props.valores.aneis;
        for (let anel in aneis) {
            if (anel) {
                aneisAtivos[anel] = aneis[anel].ativo ? '' : 'hidden';
            }
        }

        return (
            // <form onSubmit={handleSubmit} ref={(el) => (this.componentRef = el)}>
            <form onSubmit={handleSubmit} ref={this.componentRef} id='formControlador'>

                <div className='box-body'>
                    {/* <Field name='id' component={LabelAndInput} readOnly={true}
                        label='Código' cols='12' placeholder='Automático' /> */}
                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleCloseModal}
                        dialogClassName='modal-load'
                        backdrop={false} >
                        <Modal.Body className='modal-body modal-load-body'>
                            <div >
                                <div >
                                    <ReactLoading className='react-loading' type="spokes" color="#367fa9" height={50} width={50} />
                                </div>
                                <div >
                                    <h4><b>Aguarde, processando...</b></h4>
                                    <h5>{this.state.messageModal}</h5>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <If condicao={readOnly}>
                        <Field name='numero_controlador' component={LabelAndInput} readOnly={true}
                            label='N. Controlador*' cols='12' placeholder='Automático' />
                    </If>

                    <If condicao={!readOnly}>
                        <SelectNumeroControlador cols='12' name="numero_controlador" label='N. Controlador*' validate={requiredSelect} />
                    </If>

                    {/* <Field
                        name='numero_controlador'
                        component={LabelAndInput}
                        type='number'
                        // id="tempo_vermelho_min"
                        label='N. Controlador*'
                        cols='12'
                        placeholder='1 à 99'
                        validate={required}
                    // readOnly={validaPermissaoReadOnly('CadControlador', 'tempo_minimo')}
                    /> */}

                    <PermissaoUsuario tela="CadControlador" campo="cep" condicao={true}>
                        <Field
                            name='cep'
                            component={LabelAndInput}
                            label='CEP*'
                            cols='12'
                            maxLength={8}
                            placeholder='Informe só números'
                            onKeyDown={InputValidacao.validaSoNumeroInteiro}
                            onBlur={(e) => { this.cepOnBlur(e) }}
                            // props={{ onBlur: this.cepOnBlur }}
                            validate={[required, minLengthCEP]}
                        />
                    </PermissaoUsuario>

                    <PermissaoUsuario tela="CadControlador" campo="cep" condicao={false}>
                        <Field
                            name='cep'
                            component={LabelAndInput}
                            label='CEP*'
                            cols='12'
                            readOnly={true}
                        />
                    </PermissaoUsuario>

                    <Field
                        name='ip'
                        component={LabelAndInput}
                        ref='controladorip'
                        label='IP do Controlador*'
                        cols='12 6'
                        placeholder='Informe o IP'
                        validate={required}
                        readOnly={validaPermissaoReadOnly('CadControlador', 'ip_porta_controlador')}
                    />

                    <Field
                        name='porta'
                        component={LabelAndInput}
                        label='Porta do Controlador*'
                        cols='12 6'
                        placeholder='Informe a porta'
                        validate={required}
                        readOnly={validaPermissaoReadOnly('CadControlador', 'ip_porta_controlador')}
                    />

                    <ConexaoUDPTeste udp={enderecoUDP} numCont={this.props.valores.numero_controlador} />


                    <Field
                        name='data_inicio_verao'
                        id="data_inicio_verao"
                        component={LabelAndInput}
                        type='date'
                        label='Início Horário Verão'
                        cols='12 6'
                        placeholder='Informe a data'
                        readOnly={validaPermissaoReadOnly('CadControlador', 'inicio_fim_horario_vereao')}
                    />

                    <Field
                        name='data_fim_verao'
                        id="data_fim_verao"
                        component={LabelAndInput}
                        type='date'
                        label='Fim Horário Verão'
                        cols='12 6'
                        placeholder='Informe a data'
                        readOnly={validaPermissaoReadOnly('CadControlador', 'inicio_fim_horario_vereao')}
                    />

                    <PermissaoUsuario tela="CadControlador" campo="inicio_fim_horario_vereao" condicao={true}>
                        <div className='box-footer'>
                            <Button type='button' className='primary' icon='upload' label='Enviar Horário de Verão' onClick={this.enviarHorarioVeraoControlador} />
                        </div>
                    </PermissaoUsuario>

                    <PermissaoUsuario tela="CadControlador" campo="inicio_fim_horario_vereao" condicao={false}>
                        <div className='box-footer'>
                            <Button type='button' className='primary' icon='upload' label='Enviar Horário de Verão' disabled="disabled" />
                        </div>
                    </PermissaoUsuario>



                    <Field name='latitude' id='controador_latitude' component={LabelAndInput} readOnly={true}
                        label='Latitude' cols='12 6' placeholder='Informe a latitude' />

                    <Field name='longitude' id='controador_longitude' component={LabelAndInput} readOnly={true}
                        label='Longitude' cols='12 6' placeholder='Informe a longitude' />

                    <PermissaoUsuario tela="CadControlador" campo="adicionar_local_mapa" condicao={true}>
                        <div className='box-footer'>
                            {/* <If condicao={!this.props.controladorId}> */}
                            <Button type='button' className='success' icon='map-marked-alt' label='Adicionar localização ao Mapa' onClick={this.handleShow} />
                            {/* </If>
                            <If condicao={this.props.controladorId}>
                                <Button type='button' className='success' icon='map-marked-alt' label='Adicionar localização ao Mapa' disabled="disabled" />
                            </If> */}
                        </div>
                    </PermissaoUsuario>

                    <PermissaoUsuario tela="CadControlador" campo="adicionar_local_mapa" condicao={false}>
                        <div className='box-footer'>
                            <Button type='button' className='success' icon='map-marked-alt' label='Adicionar localização ao Mapa' disabled="disabled" />
                        </div>
                    </PermissaoUsuario>

                    <Field name="nobreak" component={Checkbox} label='Nobreak' type='checkbox' />

                    <Field name='monitora_erro' component={LabelAndSelectForm} label='Monitorar Conexão' >
                        <option value='1'>Sim</option>
                        <option value='0'>Não</option>
                    </Field>

                    <Modal show={this.state.show} onHide={this.handleClose} dialogClassName='modal-mapa'>
                        <Modal.Header className='modal-header' closeButton>
                            <Modal.Title>Mapa de Controlador</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='modal-body'>

                            {this.state.htmlMapaCont}

                            {/* <MapaControlador
                                data-contId={controladorId}
                                data-latitude={latitude}
                                data-longitude={longitude}
                                latitude={this.state.cepLat}
                                longitude={this.state.cepLng}
                            /> */}

                        </Modal.Body>
                        <Modal.Footer className='modal-header'>
                            <Button type='button' className='primary' icon='check' label='OK' onClick={this.clickOk} />
                        </Modal.Footer>
                    </Modal>

                </div>

                <div className='nav-tabs-custom'>
                    <ul className='nav nav-tabs'>
                        <li className={this.state.showTab === 'tabAnel' ? 'active' : ''}>
                            <span className={'button_tabs'} data-toggle='tab' onClick={() => this.handleShowTab('tabAnel')} data-target='tabAnel'>
                                <i className={`fa fa-cog`}></i> Configurações de Anéis
                            </span>
                        </li>
                        <li className={this.state.showTab === 'tabLaco' ? 'active' : ''}>
                            <span className={'button_tabs'} data-toggle='tab' onClick={() => this.handleShowTab('tabLaco')} data-target='tabLaco'>
                                <i className={`fa fa-pencil-alt`}></i> Descrição dos Laços
                            </span>
                        </li>
                        <li className={this.state.showTab === 'tabCroqui' ? 'active' : ''}>
                            <span className={'button_tabs'} data-toggle='tab' onClick={() => this.handleShowTab('tabCroqui')} data-target='tabCroqui'>
                                <i className={`fa fa-cog`}></i> Croqui
                            </span>
                        </li>
                    </ul>
                    <div className='tab-content'>
                        <div id='tabAnel' className={`tab-pane ${this.state.showTab === 'tabAnel' ? 'active' : ''} `}>
                            <div className='box box-primary'>
                                <div className='box-body'>
                                    <Field
                                        name='anel'
                                        component={LabelAndSelectForm}
                                        label='Anel'
                                        onChange={(event) => {
                                            // O anel atual tem que receber a fase do state para funcionar a gravação de latitudo e logitudo
                                            this.props.valores.aneis[this.props.valores.anel - 1].fases = this.state.fases;
                                            this.anelChange(event.target.value - 1)
                                        }}
                                    >
                                        <option value='1'>1</option>
                                        <option hidden={aneisAtivos[1]} value='2'>2</option>
                                        <option hidden={aneisAtivos[2]} value='3'>3</option>
                                        <option hidden={aneisAtivos[3]} value='4'>4</option>

                                        {/* <option disabled={this.props.valores.aneis[0].ativo ? '' : 'disabled'} value='1'>1</option>
                                        <option disabled={this.props.valores.aneis[1].ativo ? '' : 'disabled'} value='2'>2</option>
                                        <option disabled={this.props.valores.aneis[2].ativo ? '' : 'disabled'} value='3'>3</option>
                                        <option disabled={this.props.valores.aneis[3].ativo ? '' : 'disabled'} value='4'>4</option> */}
                                    </Field>

                                    <Field
                                        name='endereco'
                                        component={LabelAndInput}
                                        label='Endereço*'
                                        cols='12'
                                        placeholder='Informe o endereço'
                                        validate={[required, minLength2]}
                                        readOnly={validaPermissaoReadOnly('CadControlador', 'endereco')}
                                        onBlur={(e) => {
                                            this.props.valores.aneis[this.props.valores.anel - 1].endereco = e.target.value;
                                        }}
                                    />

                                    <Field
                                        name='cruzamento'
                                        component={LabelAndInput}
                                        label='Cruzamento*'
                                        cols='12'
                                        placeholder='Informe a descrição do cruzamento'
                                        validate={[required, minLength2]}
                                        readOnly={validaPermissaoReadOnly('CadControlador', 'cruzamento')}
                                        onBlur={(e) => {
                                            this.props.valores.aneis[this.props.valores.anel - 1].cruzamento = e.target.value;
                                        }}
                                    />

                                    <PermissaoUsuario tela="CadControlador" campo="numero_fases" condicao={true}>
                                        <SelectFaseControlador
                                            label='Fase Inicial'
                                            name='numero_fases'
                                            faseIni={1}
                                            disabled="disabled"
                                        />
                                    </PermissaoUsuario>

                                    <PermissaoUsuario tela="CadControlador" campo="numero_fases" condicao={false}>
                                        <SelectFaseControlador
                                            label='Fase Inicial'
                                            name='numero_fases'
                                            faseIni={1}
                                            disabled="disabled"
                                        />
                                    </PermissaoUsuario>

                                    <SelectFaseControlador
                                        label='Fase Final'
                                        name='numero_fases_fim'
                                        // faseIni={this.props.valores.aneis[this.props.valores.anel - 1].numero_fases}
                                        faseIni={this.props.valores.numero_fases}
                                        disabled="disabled"
                                        onChange={() => { this.geraGridFase(this.props.valores.anel - 1) }}
                                    />

                                    <FaseConflitoControlador
                                        // numeroFases={numeroFases}
                                        numeroFasesIni={this.props.valores.aneis[this.props.valores.anel - 1].numero_fases}
                                        numeroFases={this.props.valores.aneis[this.props.valores.anel - 1].numero_fases_fim}
                                        disabled={true}
                                    // disabled={validaPermissaoDisabled('CadControlador', 'fase_conflitante')}
                                    />

                                </div>
                                <div className='box-footer'>
                                    {this.state.html}
                                </div>
                                <div className='box-body'>
                                    <Field
                                        name='tempo_vermelho_min'
                                        component={LabelAndInput}
                                        type='number'
                                        id="tempo_vermelho_min"
                                        label='Tempo vermelho mínimo(seg)*'
                                        cols='12 4'
                                        placeholder='1,0 à 20,0 segundos'
                                        // readOnly={validaPermissaoReadOnly('CadControlador', 'tempo_minimo')}
                                        readOnly={true}
                                    />

                                    <Field
                                        name='tempo_amarelo_min'
                                        component={LabelAndInput}
                                        type='number'
                                        id="tempo_amarelo_min"
                                        label='Tempo amarelo mínimo(seg)*'
                                        cols='12 4'
                                        placeholder='3,0 à 20,0 segundos'
                                        // readOnly={validaPermissaoReadOnly('CadControlador', 'tempo_minimo')}
                                        readOnly={true}
                                    />

                                    <Field
                                        name='tempo_verde_min'
                                        component={LabelAndInput}
                                        type='number'
                                        id="tempo_verde_min"
                                        label='Tempo verde mínimo(seg)*'
                                        cols='12 4'
                                        placeholder='3,0 à 20,0 segundos'
                                        // readOnly={validaPermissaoReadOnly('CadControlador', 'tempo_minimo')}
                                        readOnly={true}
                                    />
                                </div>
                                <div className='box-footer'>
                                    <PermissaoUsuario tela="CadControlador" campo="ler_fase_tempo" condicao={true}>
                                        <Button type='button' className='primary' icon='download' label='Ler Plano de Segurança' onClick={this.lerFaseTempoControlador} />
                                    </PermissaoUsuario>
                                    <PermissaoUsuario tela="CadControlador" campo="ler_fase_tempo" condicao={false}>
                                        <Button type='button' className='primary' icon='download' label='Ler Plano de Segurança' disabled="disabled" />
                                    </PermissaoUsuario>

                                    {/* <PermissaoUsuario tela="CadControlador" campo="enviar_fase_tempo" condicao={true}>
                                        <Button type='button' className='primary' icon='upload' label='Enviar Fase/Tempo' onClick={this.enviarFaseTempoControlador} />
                                    </PermissaoUsuario>
                                    <PermissaoUsuario tela="CadControlador" campo="enviar_fase_tempo" condicao={false}>
                                        <Button type='button' className='primary' icon='upload' label='Enviar Fase/Tempo' disabled="disabled" />
                                    </PermissaoUsuario> */}
                                </div>
                            </div>
                        </div>
                        <div id='tabLaco' className={`tab-pane ${this.state.showTab === 'tabLaco' ? 'active' : ''} `}>
                            <div className='box box-primary'>
                                <div className='box-body'>
                                    {this.renderFieldLaco()}
                                </div>
                            </div>
                        </div>
                        <div id='tabCroqui' className={`tab-pane ${this.state.showTab === 'tabCroqui' ? 'active' : ''} `}>
                            <div className='box box-primary'>
                                <div className='box-body'>
                                    <div>
                                        <Field name="croqui_file" id="croqui_file" component={this.renderFileInput} />
                                        {this.state.showCroquiDeleteWarning && <p style={{ color: 'red' }}>Arquivo do croqui será deletado!</p>}
                                        {this.state.croquiFileErrorMessage && <p style={{ color: 'red' }}>{this.state.croquiFileErrorMessage}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='box-footer'>
                    {/* <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} /> */}
                    {/* <Button type='button' className='danger' icon='check' label='Salvar - TESTE'
                        onClick={() => {
                            this.props.valores.fases = this.state.fases;
                            if (!this.verificaAlteracao()) {
                                this.props.handleSubmit();
                            } else {

                                this.setState({ showModal: true }); // da show no modal de aguarde
                                this.props.handleSubmit();
                            }
                        }} /> */}
                    {/* <Button type='button' className='primary' icon='check' label='Salvar/Enviar Controlador' onClick={this.salvarControlador} /> */}
                    <Button type='button' className='primary' icon='check' label='Salvar' onClick={this.salvarControlador} />
                    {/* <Button type='button' className='danger' icon='image' labal='salvar imagem'
                        onClick={() => {
                            // encodeSvg(this.componentRef);

                            // console.log('formControlador',document.querySelector("#formControlador"));

                            // console.log('this.componentRef',this.componentRef.current);

                            html2canvas(this.componentRef.current)
                                .then((canvas) => {
                                    console.log(canvas);
                                    canvas.style.display = 'none';
                                    let image = canvas.toDataURL("png");

                                    // document.querySelector("#testeimg").setAttribute('src', image);

                                    console.log('image',image);
                                    // var a = document.createElement("a");
                                    // a.setAttribute('download', 'myImage.png');
                                    // a.setAttribute('href', image);
                                    // a.click();
                                });

                            // exportComponentAsJPEG(this.componentRef, { fileName: 'controlador' });
                            // exportComponentAsPNG(this.componentRef, { fileName: 'controlador' });
                        }}
                    /> */}

                    <Button
                        type='button'
                        className='default'
                        icon='close'
                        label='Cancelar'
                        onClick={() => {
                            this.setState({ fases: [] });
                            this.setState({ html: <div></div> });
                            this.props.init();
                        }}
                    />
                    <img id='testeimg' src="" alt="" />
                </div>
            </form>
        )
    }
}

// const mapStateToProps = state => ({ list: state.controladorCad.list })

// destroyOnUnmount: false  permite utilizar o mesmo componente form para cadastrar e para alterar, se tirar essa propriedade o sistema cria um
// form limpo para o update
ControladorForm = reduxForm({ form: 'controladorForm', destroyOnUnmount: false })(ControladorForm);

// aula 151 - utilizado para pegar o dado do form e jogar dentro do objeto props, depois ele é extraido no render()
const selector = formValueSelector('controladorForm');
const mapStateToProps = state => ({
    enderecoUDP: {
        ip: selector(state, 'ip'),
        porta: selector(state, 'porta')
    },
    numeroFases: selector(state, 'numero_fases'),
    latitude: selector(state, 'latitude'),
    longitude: selector(state, 'longitude'),
    controladorId: selector(state, 'id'),
    contAtivo: selector(state, 'ativo'),
    grupo_fisico: selector(state, 'grupo_fisico_id'),
    cep: selector(state, 'cep'),

    valores: getFormValues('controladorForm')(state),

    croqui_file_id: selector(state, 'croqui_file_id'),
    croqui_file_data: selector(state, 'croqui_file'),
    croqui_file_name: selector(state, 'croqui_file_name')
})

const mapDispatchToProps = dispatch => bindActionCreators({
    init,
    getList,
    getFaseList,
    getFaseListNovo,
    create,
    enviarTemposControlador,
    lerQuantidadeAneis,
    lerTemposControlador,
    lerDadosFasesControlador,
    enviarHorarioVeraoControlador,
    enviaDadosFasesControlador,
    getLocalCEP,
    getImageCroquiById
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ControladorForm);