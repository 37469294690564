import axios from 'axios'
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';
import userLocal from '../configuracao/userLocal';

import consts from '../consts';
const BASE_URL = consts.API_URL;
const INITIAL_VALUES = {}

export function getList() {

    var grupoId = configLocal.getConfig('grupo');
    var numCont = configLocal.getConfig('numCont');

    const request = axios.get(`${BASE_URL}/controladoreserro/${grupoId}/${numCont}`, {
        params: { email: userLocal.getUserConfig('email') }
    })

    return {
        type: 'CONTROLADOR_ERRO_FETCHED',
        payload: request
    }
}

export function getAlertaErro() {

    // var contId = configLocal.getConfig('cont');
    const email = userLocal.getUserConfig('email');

    const request = axios.get(`${BASE_URL}/controladoreserro/${email}`)

    return {
        type: '',
        payload: request
    }
}

export function clearAlertaErro(alerts) {

    const request = axios.put(`${BASE_URL}/controladoreserro`, { alerts: alerts });

    return {
        type: '',
        payload: request
    }
}


export function getRemoteList() {

    const contId = configLocal.getConfig('cont');
    const numCont = configLocal.getConfig('numCont');

    const request = axios.get(`${BASE_URL}/controladoreserro/remote/${contId}/${numCont}`, {
        params: { email: userLocal.getUserConfig('email') }
    });

    return {
        type: '',
        payload: request
    }
}

export function init() {
    INITIAL_VALUES.controlador_id = configLocal.getConfig('cont');
    return [
        showTabs('tabList'),
        selectTab('tabList'),
        getList()
    ]
}
