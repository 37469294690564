import axios from 'axios';
import configLocal from '../../configuracao/configLocal';

import consts from '../../consts';
const BASE_URL = consts.API_URL;

export function getPlanoList(contId) {


    const id = parseInt(contId) ? contId : configLocal.getConfig('cont');
    // var grupoId = configLocal.getConfig('grupo');
    // var numCont = configLocal.getConfig('numCont');

    // const request = axios.get(`${BASE_URL}/planos/planos/controlador/${grupoId}/${numCont}`)
    const request = axios.get(`${BASE_URL}/planos/planos/controlador/${id}`)

    return {
        type: 'PLANO_FETCHED',
        payload: request
    }
}

export function getPlanoAllList() {

    const request = axios.get(`${BASE_URL}/planos/planos/all`)

    return {
        type: 'PLANO_FETCHED',
        payload: request
    }
}

export function getControladorList() {
    const request = axios.get(`${BASE_URL}/controladores`)

    return {
        // type: 'CONTROLADOR_FETCHED',
        type: 'CONTROLADORHEADER_FETCHED',
        payload: request
    }
}

export function getControladorGrupoList() {

    const grupoFisicoId = configLocal.getConfig('grupo');

    const request = axios.get(`${BASE_URL}/controladores/${grupoFisicoId}`)

    return {
        type: 'CONTROLADOR_FETCHED',
        payload: request
    }
}

export function getConfControladorGrupoList() {

    const grupoFisicoId = configLocal.getConfig('grupo');

    const request = axios.get(`${BASE_URL}/controladores/conf/${grupoFisicoId}`)

    return {
        type: 'CONTROLADOR_CONF_FETCHED',
        payload: request
    }
}

export function getControladorAnelList() {

    // var grupoId = configLocal.getConfig('grupo');
    // var numCont = configLocal.getConfig('numCont');

    // const request = axios.get(`${BASE_URL}/controladores/aneis/${grupoId}/${numCont}`)
    const request = axios.get(`${BASE_URL}/controladores/aneis/all`)

    return {
        // type: 'CONTROLADOR_FETCHED',
        type: 'CONTROLADORANEL_FETCHED',
        payload: request
    }
}

export function getGrupoFisicoList() {
    const request = axios.get(`${BASE_URL}/gruposfisicos`)

    return {
        type: 'GRUPO_FISICO_FETCHED',
        payload: request
    }
}

export function getGrupoUsuarioList() {
    const request = axios.get(`${BASE_URL}/grupos`);

    return {
        type: 'GRUPO_FETCHED',
        payload: request
    }
}

export function getModoOperacaoList() {
    const request = axios.get(`${BASE_URL}/planos/modoop`)

    return {
        // tem que criar um reducer para percorrer o objeto e achar o MODOOPERACAO_FETCHED com isso ele pega a propriedade "data"
        type: 'MODOOPERACAO_FETCHED',
        payload: request
    }
}

export function getDetectorList(contId) {

    const request = axios.get(`${BASE_URL}/detectores/${contId}`)

    return {
        type: 'DETECTOR_FETCHED',
        payload: request
    }
}


