import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import AuthReducer from '../auth/authReducer';

import UsuarioReducer from '../usuario/usuarioReducer';
import ControladorReducer from '../controlador/controladorReducer';
import GrupoReducer from '../grupo/grupoReducer';
import PermissaoReducer from '../permissao/permissaoReducer';
import FaseReducer from '../fase/faseReducer';
import PlanoReducer from '../plano/planoReducer';
import ModoOperacaoReducer from '../plano/planoModoOperacaoReducer';
import TrocaPlanoReducer from '../troca_plano/trocaPlanoReducer';
import DataEspecialReducer from '../data_especial/dataEspecialReducer';
import IntervaloPlanoReducer from '../intervalo/intervaloReducer';
import SelecaoAutomaticaReducer from '../sap/selecaoAutomaticaReducer';
import DetectorReducer from '../detector/detectorReducer';
import TipoAlarmeReducer from '../tipo_alarme/tipoAlarmeReducer';
import GrupoFisicoReducer from '../grupo_fisico/grupoFisicoReducer';
import ControladorErro from  '../controlador_erro/erroReducer';
import ControladorContVolumetrica from '../contagem_volumetrica/contagemVolumetricaReducer';
import ContagemReducer from '../contagem/contagemReducer';
import AdaptativoReducer from '../adaptativo/adaptativoReducer';
import IntervaloAdaptativoReducer from '../adaptativo_intervalo/intervaloAdaptativoReducer';
import ControladorErroGeral from  '../controlador_erro_geral/erroGeralReducer';
import LogUsuario from  '../log_usuario/logUsuarioReducer';
import NotificacoesReducer from '../usuario/notificacoesReducer';
import IdentificadorTelegramReducer from '../telegram/identificadorTelegramReducer';


const rootReducer = combineReducers({
    dashboard: DashboardReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer,
    auth: AuthReducer,
    usuarioCad: UsuarioReducer,
    controladorCad: ControladorReducer,
    grupoCad: GrupoReducer,
    permissaoCad: PermissaoReducer,
    faseCad: FaseReducer,
    planoCad : PlanoReducer,
    modoOperacaoCon : ModoOperacaoReducer, // usado só para consultas para mostrar em <select> 
    trocaPlanoCad : TrocaPlanoReducer,
    dataEspecialCad : DataEspecialReducer,
    intervaloPlanoCad : IntervaloPlanoReducer,
    selecaoAutomaticaCad : SelecaoAutomaticaReducer,
    detectorCad : DetectorReducer,
    tipoAlarmeCad : TipoAlarmeReducer,
    grupoFisicoCad : GrupoFisicoReducer,
    controladorErroCad : ControladorErro,
    controladorContagemVolumetrica : ControladorContVolumetrica,
    contagemaList : ContagemReducer,
    adaptativoCad: AdaptativoReducer,
    intervaloAdaptativoCad: IntervaloAdaptativoReducer,
    controladorErroGeralCad:ControladorErroGeral,
    logusuarioCad:LogUsuario,
    notificacoesList: NotificacoesReducer,
    identificadorTelegramCad: IdentificadorTelegramReducer,
})

export default rootReducer