import axios from 'axios'
import { toastr } from 'react-redux-toastr'
//  import { reset as resetForm, initialize } from 'redux-form'
//  import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';
import consts from '../consts';
const BASE_URL = consts.API_URL;

// const INITIAL_VALUES = {}

export function getList(contid) {

    const request = axios.get(`${BASE_URL}/fases/${contid}`);

    return {
        type: 'FASE_FETCHED',
        payload: request 
    }
}

export function getControladorFase() {
    const request = axios.get(`${BASE_URL}/fases/mapa/geral`)

    return {
        type: `FASE_FETCHED`,
        payload: request
    }
}

export function getControladorCores() {
    const request = axios.get(`${BASE_URL}/fasesgeral/mapa`);

    return {
        type: `FASEMAP_FETCHED`,
        payload: request
    }   
}

export function getControladorCoresGT(contId) {
    const request = axios.get(`${BASE_URL}/fasesgeral/mapagt/${contId}`);

    return {
        type: `FASEMAP_FETCHED`,
        payload: request
    }   
}

export function getControladorCoresGF(grupoId) {
    const request = axios.get(`${BASE_URL}/fasesgeral/mapagf/${+grupoId}`);

    return {
        type: `FASEMAP_FETCHED`,
        payload: request
    }   
}

export function getControladorList() {
    let grupoFisicoId = configLocal.getConfig('grupo');
    const request = axios.get(`${BASE_URL}/fasesgeral/mapagt/controlador/${grupoFisicoId}`);

    return {
        type: `FASEMAPCONTROLADORLIST_FETCHED`,
        payload: request
    }   
}

export function getInicioMapa(grupo) {
    const request = axios.get(`${BASE_URL}/fasesgeral/mapa/inicio/${+grupo}`);

    return {
        type: `FASEMAPAINICIO_FETCHED`,
        payload: request
    }   
}

export function update(values) {
    return submit(values, 'put')
}

function submit(values, method) {

    return dispatch => {
        const id = values.id ? values.id : '';
        const controlador_id = values.controlador_id;
        
        axios[method](`${BASE_URL}/fases/${controlador_id}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.');
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function init() {
    return [
        getList()
    ]
}
