import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { init } from './usuarioActions';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndSelectForm from '../common/form/labelAndSelectForm';
import Button from '../common/form/button';
import SelectGrupoUsuario from '../common/selectdb/selectGrupoUsuario';

class UsuarioForm extends Component {

    render() {
        // os eventos handleSubmit, vem do reduxForm
        const { handleSubmit, readOnly } = this.props;

        return (
            <form  onSubmit={handleSubmit}>
                <div className='box-body'>
                    <Field name='id' component={LabelAndInput} readOnly={true}
                        label='Código' cols='12' placeholder='Automático' />
                    <Field name='nome' component={LabelAndInput}
                        label='Nome' cols='12' placeholder='Informe o nome' />
                    <Field name='usuario' component={LabelAndInput} readOnly={readOnly}
                        label='Email' cols='12' placeholder='Informe o email para login' />
                    <Field name='senha' component={LabelAndInput} type='password' readOnly={readOnly}
                        label='Senha' cols='12' placeholder='Informe a senha' />
                    <Field name='confirmarSenha' component={LabelAndInput} type='password' readOnly={readOnly}
                        label='Confirmar Senha' cols='12' placeholder='Confirme a senha' />

                    <SelectGrupoUsuario name='grupo_idgrupo' label='Grupo Usuário' cols='12' />

                    <Field name='ativo' component={LabelAndSelectForm} label='Ativo' >
                        <option value='1'>Sim</option>
                        <option value='0'>Não</option>
                    </Field>
                </div>
                <div className='box-footer '>
                    <Button type='submit' className={this.props.submitClass} icon='check' label={this.props.submitLabel} />
                    <Button type='button' className='default' icon='close' label='Cancelar' onClick={this.props.init} />
                </div>
            </form>
        )
    }
}


UsuarioForm = reduxForm({ form: 'usuarioForm', destroyOnUnmount: false })(UsuarioForm);
const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch);
export default connect(null, mapDispatchToProps)(UsuarioForm);