import axios from 'axios'
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';
import userLocal from '../configuracao/userLocal';
import consts from '../consts';
import { PAGE_SIZE } from '../common/utils/pageSize';
const BASE_URL = consts.API_URL;

export function getList(page, sorted, filtered) {
    const email = userLocal.getUserConfig('email');
    const request = axios.get(`${BASE_URL}/controladoreserrogeral`, { 
        params: {
            page,
            perPage: PAGE_SIZE,
            email,
            sort: sorted,
            filter: filtered
        }
    });

    return {
        type: 'CONTROLADOR_ERRO_GERAL_FETCHED',
        payload: request
    }
}

export function getErrosList(filtro) {
    const dataIni = filtro.dataInicial;
    const dataFim = filtro.dataFinal;
    const grupo   = configLocal.getConfig('grupo');
    const email = userLocal.getUserConfig('email');

    let url = `${BASE_URL}/controladoreserrogeral/${dataIni}/${dataFim}/${grupo}`;
    if (filtro.controlador !== null) {
        var cont = filtro.controlador;
        url += `/${cont}`;
    }

    const request = axios.get(url, {
        params: { email }
    });

    return {
        type: 'CONTROLADOR_ERRO_GERAL_GRAFICO_FETCHED',
        payload: request
    }
}

export function init() {
    return [
        showTabs('tabList', 'tabGraph'),
        selectTab('tabList'),
        getList(0)
    ]
}
