import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { reset as initialize } from 'redux-form'
import { showTabs, selectTab } from '../common/tab/tabActions'
import configLocal from '../configuracao/configLocal';
import consts from '../consts';
const BASE_URL = consts.API_URL;

// const INITIAL_VALUES = {};
const INITIAL_VALUES = [{controlador_id : configLocal.getConfig('cont')}];

export function getIntervaloList(planoid) {

    var contId = configLocal.getConfig('cont');

    const request = axios.get(`${BASE_URL}/adaptativos/intervalo/${contId}/${planoid}`)

    return {
        type: 'INTERVALO_ADAPTATIVO_FETCHED',
        payload: request
    }
}

export function getListControlador(contid, planoid) {
        
    const request = axios.get(`${BASE_URL}/adaptativos/remote/${contid}/${planoid}`);
    
    return {
        type: 'INTERVALO_ADAPTATIVO_FETCHED',
        payload: request
    }
}

export function updateControlador(values) {

    let contId = configLocal.getConfig('cont');

    const request = axios.put(`${BASE_URL}/adaptativos/remote/grava/${contId}`, values);
    
    return {
        type: '',
        payload: request
    }

}

export function create(values) {
    return submit(values, 'post');
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete');
}

export function showUpdate(plano) {
    // console.log(plano);
    return [
        showTabs('tabUpdate'),
        selectTab('tabUpdate')
    ]
}

function submit(values, method) {

    return dispatch => {
        const id = method != 'post' ? values.id : ''
        const contId = values.controlador_id;
        axios[method](`${BASE_URL}/adaptativos/${contId}/${id}`, values)
            .then(resp => {
                toastr.success('Sucesso', 'Operação Realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                e.response.data.errors.forEach(error => toastr.error('Erro', error))
            })
    }
}

export function init() {
    // INITIAL_VALUES = [{controlador_id : configLocal.getConfig('cont')}];
   
    return [
        showTabs('tabList', 'tabCreate'),
        selectTab('tabList'),
        
        // getListCodigo(),// tem que colocar aqui para atualizar quando salva um novo
        initialize('adaptativoForm2', INITIAL_VALUES)
    ]
}
