require('dotenv').config();

console.log(process.env);
// console.log(process.env.API_URL);

export default {
    // API_URL: 'http://192.168.1.111:3003/api',
    // OAPI_URL: 'http://192.168.1.111:3003/oapi',

    // API_URL: 'http://10.8.1.23:14003/api',// vpn
    // OAPI_URL: 'http://10.8.1.23:14003/oapi',
    
    // API_URL: 'https://centralssat.com.br:14001/api',
    // OAPI_URL: 'https://centralssat.com.br:14001/oapi',

    // API_URL: 'https://ijui.centralssat.com.br:14003/api',
    // OAPI_URL: 'https://ijui.centralssat.com.br:14003/oapi',

    //API_URL: 'http://localhost:14201/api',
    //OAPI_URL: 'http://localhost:14201/oapi',

    API_URL: 'https://testessat.centralssat.com.br:14201/api',
    OAPI_URL: 'https://testessat.centralssat.com.br:14201/oapi',    

    SERVER_IP: 'localhost',// cria um client tcp na maquina local(NÃO É USADO, ERA SÓ TESTE)
    // SERVER_IP: '192.168.1.19',
    SERVER_PORTA: 5000,// porta utilizada para conectar no server de comunicação(NÃO É USADO, ERA SÓ TESTE)
    NUM_INTERVALO: 48,
    NUM_FASE: 16
}